<template>
    <div>   
        <div>
            <v-dialog 
                v-model="dialog_show_doc"
                scrollable   
                :persistent="false" :overlay="false"
                :max-width="width?width:DialogWidth"
                transition="dialog-transition" >
                <v-card rounded="lg" class="b1">  
                    <div v-if="document_type=='payment'"> 
                        <mbs-doc-show-sales-payment 
                            :top_header="true"
                            :height="height?height:vsh-220"
                            :title="title"
                            :color="color"
                            :data="data"
                            :data_key="data_key"
                            :document_type="document_type"
                            :back_btn="back_btn"
                            :print_btn="print_btn"
                            @on_close="ON_CLOSE"
                        /> 
                    </div>
                    <div v-if="document_type=='return'"> 
                        <mbs-doc-show-sales-return 
                            :top_header="true"
                            :height="height?height:vsh-220"
                            :title="title"
                            :color="color"
                            :data="data"
                            :data_key="data_key"
                            :document_type="document_type"
                            :back_btn="back_btn"
                            :print_btn="print_btn"
                            @on_close="ON_CLOSE"
                        /> 
                    </div>
                    <div v-else> 
                        <mbs-doc-show-sales-invoice 
                            :top_header="true"
                            :height="height?height:vsh-220"
                            :title="title"
                            :color="color"
                            :data="data"
                            :data_key="data_key"
                            :document_type="document_type"
                            :back_btn="back_btn"
                            :print_btn="print_btn"
                            @on_close="ON_CLOSE"
                        /> 
                    </div>
                    
                </v-card> 
            </v-dialog>   
        </div>
    </div> 
</template> 
<script> 

    import DATA from "../../../plugins/DATA";
    import {mapState} from "vuex" 
    import DB from "../../../plugins/DB"; 
    const PAGE_NAME = "SHOW_INVOICE"

    export default {
        props:[
            'height','width','title','color','data','data_key','document_type','back_btn','print_btn','value'],
        data(){
            return{
                dialog_show_doc:this.value,   
                auto_print:{
                    auto:false,
                    printing:false, 
                    print_data:null,
                    print_type:'sale_order',
                    print_title:'INVOICE',
                    com_printer:false, 
                    printer_name:null,
                    printing_action:null, 
                    display_option:'', 
                }, 
            }
        },
        created(){ 
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
                this.MBS.events.$on('ON_DOC_DIALOG', this.ON_DOC_DIALOG);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted(){  
             
        },
        computed:{
            vs(){
                let vs = this.$vuetify.breakpoint 
                return vs
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
                K_CONNECTOR: state=> state.items['K_CONNECTOR'],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
                 
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            }), 
            DataSaleOrders(){
                let SaleOrders = this.SaleOrders
                let JoinedSaleOrders = this.JoinedSaleOrders
                return JoinedSaleOrders?JoinedSaleOrders:SaleOrders
            },
            AllLocalSaleOrdersData(){  
                const LocalSaleOrders = this.LocalSaleOrders
                const JoinedLocalSaleOrders = this.JoinedLocalSaleOrders
                let items = JoinedLocalSaleOrders?JoinedLocalSaleOrders:LocalSaleOrders  
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
                return list
            }, 
            BothSaleOrders(){
                let orders = this.DataSaleOrders
                let localOrders = this.AllLocalSaleOrdersData 
                if(!orders && !localOrders){return null}
                if(orders && !localOrders){return orders}
                if(!orders && localOrders){return localOrders}

                let both = [...orders]
                localOrders.forEach(element => {
                    let item = orders.find(item=>{
                        return item.key == element.key
                    })
                    if (!item) {
                        both.push(element)
                    } 
                });
                let list = [...both]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                
                return list
            },
            ThisSaleOrder(){ 
                let orders = this.BothSaleOrders
                let data_key = this.data_key
                let data = this.data
                console.log(data_key,'key..');
                if (data) {
                    return data
                }
                if(!orders || !data_key){return null}
                let items = orders.find(order=>{
                    return order.key == data_key
                })
                return items
            },  
            ReceiptNumber(){  
                let data_key = this.data_key
                let data = this.data 
                let order = this.ThisSaleOrder 
                let receipt_number_ = order?order.receipt_number_:null
                if (receipt_number_) {
                    return receipt_number_
                }
                if (data) {
                    return data.key
                } 
                return data_key
            },  
            InvoiceSettings(){
                let settings = this.CompanySettings 
                let template_settings = settings?settings.template_settings:null 
                let invoice = template_settings?template_settings.invoice:null 
                return invoice
            }, 
            ReceiptSettings(){
                let settings = this.CompanySettings 
                let template_settings = settings?settings.template_settings:null 
                let receipt = template_settings?template_settings.receipt:null 
                return receipt
            }, 
            quotationSettings(){
                let settings = this.CompanySettings 
                let template_settings = settings?settings.template_settings:null 
                let quotation = template_settings?template_settings.quotation:null 
                return quotation
            }, 
            TempSettings(){
                let InvoiceSettings = this.InvoiceSettings
                let ReceiptSettings = this.ReceiptSettings
                let quotationSettings = this.quotationSettings
                let document_type = this.document_type
                let temp_settings = null
                if (this.MBS.actions.TEXT_UP(document_type) == "RECEIPT") {
                    temp_settings = ReceiptSettings 
                } else if (this.MBS.actions.TEXT_UP(document_type) == "INVOICE") {
                    temp_settings = InvoiceSettings 
                }else if (this.MBS.actions.TEXT_UP(document_type) == "QUOTATION") {
                    temp_settings = quotationSettings 
                } else {
                    temp_settings = ReceiptSettings 
                } ;
                return temp_settings
            },
            DialogWidth(){ 
                let temp_settings = this.TempSettings  
                let is_pdf =  temp_settings?.display_options=='pdf_view'
                return is_pdf?'1200':'600'
            }
              
        },
        methods:{ 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "order_stock") {
                        let link = this.MBS.actions.COMPANY_LINK("/stock/stock-control/sales-return",true)
                        this.MBS.actions.go(link)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true)  
                    if (action == "view_order") {
                        let receipt_key = item?item.key:null
                        this.MBS.actions.go("/sales-receipt/"+receipt_key)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            FINISH_PRINT(action,payload){
                try { 
                    let canceled = payload?payload.canceled:null
                    if (canceled) {
                        
                    }else{
                        setTimeout(() => {
                                // this.MBS.actions.go(-1)   
                        }, 1); 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FINISH_PRINT',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            ON_PDF(){
                try { 
                     this.MBS.actions.dialog(null,true,false,
                        "PDF OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_PDF',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EXCEL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EXCEL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK")  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EMAIL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EMAIL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_PRINTING(){
                try { 
                    let lastSale = this.ThisSaleOrder 
                    let printer = this.CurrentReceiptPrinter
                    let printer_name = printer?printer.name:null
                    let printer_type_name = printer?printer.printer_type_name:null 
                    this.auto_print.printing = false
                    this.auto_print.display_option = this.InvoiceSettings?this.InvoiceSettings.display_options=='pdf_view'?'pdf':'':''
                    setTimeout(() => {
                        if (printer_type_name) {
                            // this.auto_print.auto = true
                        }
                        this.auto_print.printer_name = printer_name
                        this.auto_print.print_data = lastSale 
                        this.auto_print.printing = true   
                    }, 5); 
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ON_CLOSE(data){
                try { 
                    this.dialog_show_doc = false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_CLOSE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
            ON_DOC_DIALOG(data){
                try { 
                    this.dialog_show_doc = true 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_CLOSE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
        },
        beforeDestroy(){
            try {
                
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch:{ 
            us(v){
                this.PAGE_PERMISSION()
            },
            dialog_show_doc(v){
                this.$emit("input",v)
            },
        } 

    }
</script>
 